import axios from "axios";
import React, { useEffect, useState } from "react";

import { toast } from "react-toastify";
import { Languages, oldLanguageList } from "../../constants";

const initialInputValues = {
  clientName: "",
  language: "",
  fileType: "",
  startDate: "",
  cta1: "",
  cta2: "",
  url: "",
  logo: "",
  qrCode: "",
  registrationCloseDate: "",
  assetFolderList: [],
  version: "v2",
};

const initialCheckboxValues = {
  emailBanners: true,
  flyer: true,
  digitalAssets: true,
  paySlip: true,
  plasmaPpt: true,
  posters: true,
  // extendedTextboxPosters: true,
  virtualBackgrounds: true,
  webTiles: true,
};

const initialFileNames = {
  logo: "",
  qrCode: "",
};

// axios.defaults.baseURL = process.env.REACT_APP_URL_BASE;

const Asset = (props) => {
  const [selectAll, setSelectAll] = useState(true);
  const [input, setInput] = useState(initialInputValues);
  const [checkbox, setCheckbox] = useState(initialCheckboxValues);
  const [checkboxNextDisable, setCheckboxNextDisable] = useState(false);
  const [submitDisable, setSubmitDisable] = useState(false);
  const [count, setCount] = useState(1);
  const [loading, setLoading] = useState(false);
  const [fileNames, setFileNames] = useState(initialFileNames);
  const [accessToken, setAccessToken] = useState(null);

  const supportedTypes = ["image/jpg", "image/jpeg", "image/png"];

  const getUserAccessToken = async () => {
    var accessToken = await props.getAccessToken();
    setAccessToken(accessToken);
  };

  useEffect(() => {
    getUserAccessToken();
  }, []);

  const handleOnInputChange = (event) => {
    const { name, value } = event.target;
    setSubmitDisable(false);
    if (name === "version") {
      setInput({
        ...input,
        language: "",
        [name]: value,
      });
    } else {
      setInput({
        ...input,
        [name]: value,
      });
    }
  };

  const handleOnCheckboxChange = (e) => {
    setCheckboxNextDisable(false);
    const id = e.target.id;
    const updatedCheckbox = {
      ...checkbox,
      [e.target.id]: !checkbox[id],
    };
    setCheckbox(updatedCheckbox);
    if (
      JSON.stringify(initialCheckboxValues) === JSON.stringify(updatedCheckbox)
    ) {
      setSelectAll(true);
    }
    if (input.version === "v1") {
      if (
        !(
          updatedCheckbox.emailBanners &&
          updatedCheckbox.digitalAssets &&
          updatedCheckbox.flyer &&
          updatedCheckbox.paySlip &&
          updatedCheckbox.plasmaPpt &&
          updatedCheckbox.posters
        )
      ) {
        setSelectAll(false);
      }
    } else if (
      !(
        updatedCheckbox.emailBanners &&
        updatedCheckbox.digitalAssets &&
        updatedCheckbox.flyer &&
        updatedCheckbox.paySlip &&
        updatedCheckbox.posters &&
        updatedCheckbox.webTiles &&
        updatedCheckbox.virtualBackgrounds
      )
    ) {
      setSelectAll(false);
    }
  };

  const handleOnFileChange = (e) => {
    setSubmitDisable(false);
    const file = e.target.files[0];

    if (file) {
      if (supportedTypes.includes(file.type)) {
        var height, width;
        var img = new Image();
        var objectUrl = URL.createObjectURL(file);
        img.onload = function () {
          height = this.height;
          width = this.width;
          if (height <= 500 && width <= 500) {
            setFileNames({
              ...fileNames,
              [e.target.name]: file.name,
            });
            setInput({
              ...input,
              [e.target.name]: file,
            });
          } else {
            e.target.value = null;
            toast.error("Please upload an image of size smaller than 500*500");
          }

          URL.revokeObjectURL(objectUrl);
        };
        img.src = objectUrl;
      } else {
        e.target.value = null;
        toast.error("Please upload an image in JPG, JPEG or PNG format only");
      }
    }
  };

  function download(strData, strFileName) {
    var D = document,
      A = arguments,
      a = D.createElement("a"),
      n = A[1];

    var newdata = `data:application/zip ;base64, ${escape(strData)}`;
    a.href = newdata;
    if ("download" in a) {
      a.setAttribute("download", n);
      D.body.appendChild(a);
      setTimeout(function () {
        var e = D.createEvent("MouseEvents");
        e.initMouseEvent(
          "click",
          true,
          false,
          window,
          0,
          0,
          0,
          0,
          0,
          false,
          false,
          false,
          false,
          0,
          null
        );
        a.dispatchEvent(e);
        D.body.removeChild(a);
      }, 66);
      return true;
    }
  }

  const isUrlSecured = () => {
    var Url = input.url;
    if (Url) {
      Url = Url.toLowerCase();
      if (Url[0] === "h") {
        if (Url.substr(0, 5) === "https") {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    } else {
      return true;
    }
  };

  const onSubmit = async () => {
    if (
      input.cta1 ||
      input.cta2 ||
      input.url ||
      input.registrationCloseDate ||
      input.startDate ||
      input.logo ||
      input.qrCode
    ) {
      const urlValid = isUrlSecured();
      if (urlValid) {
        const dtoData = {
          userId: 1,
          assetFolderList: input.assetFolderList,
          cta1: input.cta1,
          cta2: input.cta2,
          url: input.url,
          registrationCloseDate: input.registrationCloseDate,
          clientName: input.clientName,
          language: input.language,
          startDate: input.startDate,
        };
        if (input.version === "v2") {
          dtoData.fileType = input.fileType;
        }
        const formData = new FormData();
        formData.append("vpReplacementDto", JSON.stringify(dtoData));
        formData.append(
          "logo",
          input.logo === ""
            ? new Blob([""], { type: "text/plain" })
            : input.logo,
          input.logo === "" ? "emptyLogo" : input.logo.filename
        );
        formData.append(
          "qrCode",
          input.qrCode === ""
            ? new Blob([""], { type: "text/plain" })
            : input.qrCode,
          input.qrCode === "" ? "emptyQR" : input.qrCode.filename
        );
        const config = {
          headers: {
            "X-Requested-With": "XMLHttpRequest",
            "Access-Control-Allow-Origin": "*",
            Authorization: "Bearer " + accessToken,
          },
        };

        setLoading(true);
        axios
          .post(
            `${
              input.version === "v1"
                ? process.env.REACT_APP_URL_CLIENT
                : process.env.REACT_APP_URL_BASE
            }/file/conversion`,
            formData,
            config
          )
          .then((response) => {
            switch (response.data.response.vpReplacementResponseCode) {
              case 10000:
                const strData = response.data.response.convertedZip;
                download(strData, input.clientName);
                setLoading(false);
                onNextHandler();
                toast.success("Success");
                break;
              case 10001:
                setLoading(false);
                toast.error("Asset files empty");
                break;
              default:
                toast.error("There was an error");
                setLoading(false);
                break;
            }
          })
          .catch((error) => {
            setLoading(false);
            console.error(error);
            toast.error(
              "Something went wrong. Please try again after sometime"
            );
          });
      } else {
        setSubmitDisable(true);
        toast.error("Please use a secure URL(which includes https)");
      }
    } else {
      setSubmitDisable(true);
      toast.error("Please enter data in atleast one field");
    }
  };

  const checkBoxCheck = () => {
    const folderList = [];
    if (checkbox.emailBanners) {
      if (input.version === "v1") {
        folderList.push({
          folderName: "Email Banner - PNG",
          fileType: "application/pdf",
        });
        folderList.push({
          folderName: "Email Banner - PDF",
          fileType: "application/pdf",
        });
      } else {
        folderList.push({
          folderName: "Email Banner",
          fileType: "application/pdf",
        });
      }
    }
    if (checkbox.flyer) {
      folderList.push({ folderName: "Flyers", fileType: "application/pdf" });
    }
    if (checkbox.digitalAssets) {
      folderList.push({
        folderName: "Digital Display",
        fileType: "application/pdf",
      });
    }
    if (checkbox.paySlip) {
      folderList.push({ folderName: "Payslip", fileType: "application/pdf" });
    }
    if (checkbox.plasmaPpt) {
      folderList.push({
        folderName: "Plasma PPT",
        fileType:
          "application/vnd.openxmlformats-officedocument.presentationml.presentation",
      });
    }
    if (checkbox.posters) {
      folderList.push({ folderName: "Posters", fileType: "application/pdf" });
    }
    if (checkbox.webTiles) {
      folderList.push({ folderName: "Webtiles", fileType: "application/pdf" });
    }
    // if (checkbox.extendedTextboxPosters) {
    //   folderList.push({
    //     folderName: "Extended Text Box Posters",
    //     fileType: "application/pdf",
    //   });
    // }
    if (checkbox.virtualBackgrounds) {
      folderList.push({
        folderName: "Virtual Backgrounds",
        fileType: "application/pdf",
      });
    }

    setInput({
      ...input,
      assetFolderList: folderList,
    });
    if (folderList.length > 0) {
      onNextHandler();
    } else {
      setCheckboxNextDisable(true);
      toast.error("Please select atleast one asset");
    }
  };

  const onNextHandler = () => setCount(count + 1);

  const onBackHandler = () => setCount(count - 1);

  const selectAllHandler = () => {
    setCheckboxNextDisable(false);
    if (selectAll) {
      if (input.version === "v1") {
        setCheckbox({
          emailBanners: false,
          flyer: false,
          digitalAssets: false,
          paySlip: false,
          plasmaPpt: false,
          posters: false,
        });
      } else {
        setCheckbox({
          emailBanners: false,
          flyer: false,
          digitalAssets: false,
          paySlip: false,
          posters: false,
          webTiles: false,
          virtualBackgrounds: false,
        });
      }
      setSelectAll(false);
    } else {
      setCheckbox(initialCheckboxValues);
      setSelectAll(true);
    }
  };

  const inputSpaceCheck = (e) => {
    if (e.charCode === 32 && e.target.value.length < 1) {
      e.preventDefault();
    }
  };

  return (
    <div>
      {loading ? (
        <div className="container text-center mt-5">
          <div className="lds-ring">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      ) : (
        <div className="container mt-4 mb-4 asset-container">
          {count < 4 ? (
            <div className="">
              <div className="text-center">
                <h2>DYNAMIC ASSETS</h2>
                <p style={{ color: "gray" }}>
                  Use this tool to create personalised marketing assets for your
                  clients.
                </p>
              </div>
              <div className="steps-container">
                <ul className="steps">
                  <li
                    className={`steps-list steps-first text-center ${
                      count === 1 && "step-active"
                    }`}
                  >
                    <p className="steps-p m-0">STEP 1</p>
                    <p className="steps-info">Get Started</p>
                  </li>
                  <li
                    className={`steps-list steps-second text-center ${
                      count === 2 && "step-active"
                    }`}
                  >
                    <p className="steps-p m-0">STEP 2</p>
                    <p className="steps-info">Select your assets</p>
                  </li>
                  <li
                    className={`steps-list steps-last text-center ${
                      count === 3 && "step-active"
                    }`}
                  >
                    <p className="steps-p m-0">STEP 3</p>
                    <p className="steps-info">Personalise your assets</p>
                  </li>
                </ul>
              </div>
            </div>
          ) : null}
          {count === 1 ? (
            <div className="asset-form-container mt-5">
              <form onSubmit={onNextHandler}>
                <div className="row align-items-center flex-column mt-3">
                  <div>
                    <div className="font-weight-bold">
                      <p>Client Name</p>
                    </div>
                    <input
                      type="text"
                      value={input.clientName}
                      onChange={handleOnInputChange}
                      onKeyPress={inputSpaceCheck}
                      placeholder="Enter Your Text (max 30 Characters)"
                      className="form-control form-control-lp"
                      maxLength="30"
                      name="clientName"
                      id="clientName"
                      required
                    />
                  </div>
                </div>
                <div className="row align-items-center flex-column mt-3">
                  <div>
                    <div className="font-weight-bold">
                      <p>Application Version</p>
                    </div>
                    <select
                      className="form-control form-control-lp"
                      id="version"
                      name="version"
                      onChange={(event) => {
                        handleOnInputChange(event);

                        const { value } = event.target;
                        const checkboxClone = { ...checkbox };
                        if (value === "v2") {
                          delete checkboxClone.plasmaPpt;
                          checkboxClone.virtualBackgrounds = true;
                          checkboxClone.webTiles = true;
                        } else {
                          checkboxClone.plasmaPpt = true;
                          delete checkboxClone?.virtualBackgrounds;
                          delete checkboxClone?.webTiles;
                        }
                        setCheckbox(checkboxClone);
                      }}
                      value={input.version}
                      required
                    >
                      <option value="">Choose Option</option>
                      <option value="v1">LIVE (V1)</option>
                      <option value="v2">LIVE (V2)</option>
                    </select>
                  </div>
                </div>
                {input?.version === "v2" && (
                  <div className="row align-items-center flex-column mt-3">
                    <div>
                      <div className="font-weight-bold">
                        <p>File Type</p>
                      </div>
                      <select
                        className="form-control form-control-lp"
                        id="name"
                        name="fileType"
                        onChange={handleOnInputChange}
                        value={input.fileType}
                        required
                      >
                        <option value="">Choose File Type</option>
                        <option value="pdf">PDF</option>
                        <option value="ppt">PPT</option>
                      </select>
                    </div>
                  </div>
                )}
                <div className="row justify-content-center mt-3">
                  <div>
                    <div className="font-weight-bold">
                      <p>Language</p>
                    </div>
                    <select
                      className="form-control form-control-lp"
                      name="language"
                      onChange={handleOnInputChange}
                      value={input.language}
                      required
                    >
                      <option value="">Choose Language</option>
                      {input.version === "v1"
                        ? oldLanguageList?.map((item) => (
                            <option value={item?.code} key={item.code}>
                              {item.language}
                            </option>
                          ))
                        : Languages?.map((item) => (
                            <option value={item?.code} key={item.code}>
                              {item.language}
                            </option>
                          ))}
                    </select>
                  </div>
                </div>
                <div className="row justify-content-end px-2">
                  <button
                    type="submit"
                    className="mt-3 mb-3 mr-4 btn primary-btn"
                    style={{ width: "50%" }}
                  >
                    Next &nbsp;&nbsp;{" "}
                    <i className="fas fa-long-arrow-alt-right"></i>
                  </button>
                </div>
              </form>
            </div>
          ) : null}
          {count === 2 ? (
            <>
              <div className="container">
                <div className="row mt-1 offset-8">
                  <div className="d-flex ml-5">
                    <label className="mr-3 ml-4" htmlFor="selectAll">
                      Select All
                    </label>
                    <label className="container-cb mt-1 ml-2">
                      <input
                        type="checkbox"
                        className="mt-1"
                        checked={selectAll}
                        onChange={selectAllHandler}
                        id="selectAll"
                      />
                      <span className="checkmark"></span>
                    </label>
                    <div className="col-1 mt-1">
                      <input type="checkbox" />
                    </div>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-2"></div>
                  <div className="col-4 d-flex justify-content-between p-2 px-2 mr-2 asset-box bg-white">
                    <label
                      className="my-auto form-check-label font-weight-bold"
                      htmlFor="emailBanners"
                    >
                      {input.version === "v1"
                        ? "Email Bannners/WebTiles"
                        : "Email Bannners"}
                    </label>
                    <label className="container-cb mt-1 ml-1">
                      <input
                        type="checkbox"
                        checked={checkbox.emailBanners}
                        onChange={handleOnCheckboxChange}
                        id="emailBanners"
                      />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                  <div className="col-4 d-flex justify-content-between p-2 px-2 ml-2 asset-box  bg-white">
                    <label
                      className="my-authtmlForm-check-label font-weight-bold"
                      htmlFor="paySlip"
                    >
                      Pay Slip
                    </label>
                    <label className="container-cb mt-1 ml-1">
                      <input
                        type="checkbox"
                        className="mt-1"
                        checked={checkbox.paySlip}
                        onChange={handleOnCheckboxChange}
                        id="paySlip"
                      />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                  <div className="col-2"></div>
                </div>
                <div className="row mt-3">
                  <div className="col-2"></div>
                  <div className="col-4 d-flex justify-content-between p-2 px-2 mr-2 asset-box bg-white">
                    <label
                      className="my-auto form-check-label font-weight-bold"
                      htmlFor="flyer"
                    >
                      Flyer
                    </label>
                    <label className="container-cb mt-1 ml-1">
                      <input
                        type="checkbox"
                        className="mt-1"
                        checked={checkbox.flyer}
                        onChange={handleOnCheckboxChange}
                        id="flyer"
                      />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                  <div className="col-4 d-flex justify-content-between p-2 px-2 ml-2 asset-box bg-white">
                    <label
                      className="my-auto form-check-label font-weight-bold"
                      htmlFor="digitalAssets"
                    >
                      Digital Display
                    </label>
                    <label className="container-cb mt-1 ml-1">
                      <input
                        type="checkbox"
                        className="mt-1"
                        checked={checkbox.digitalAssets}
                        onChange={handleOnCheckboxChange}
                        id="digitalAssets"
                      />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                  <div className="col-2"></div>
                </div>
                <div className="row mt-3">
                  <div className="col-2"></div>
                  <div className="col-4 d-flex justify-content-between p-2 px-2 mr-2 asset-box bg-white">
                    <label
                      className="my-auto form-check-label font-weight-bold"
                      htmlFor="posters"
                    >
                      Posters
                    </label>
                    <label className="container-cb mt-1 ml-1">
                      <input
                        type="checkbox"
                        className="mt-1"
                        checked={checkbox.posters}
                        onChange={handleOnCheckboxChange}
                        id="posters"
                      />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                  {input.version === "v1" && (
                    <div className="col-4 d-flex justify-content-between p-2 px-2 ml-2 asset-box bg-white">
                      <label
                        class="my-auto form-check-label font-weight-bold"
                        for="plasmaPpt"
                      >
                        Plasma PPT
                      </label>
                      <label className="container-cb mt-1 ml-1">
                        <input
                          type="checkbox"
                          className="mt-1"
                          checked={checkbox.plasmaPpt}
                          onChange={handleOnCheckboxChange}
                          id="plasmaPpt"
                        />
                        <span className="checkmark"></span>
                      </label>
                    </div>
                  )}
                  {input.version === "v2" && (
                    <div className="col-4 d-flex justify-content-between p-2 px-2 ml-2 asset-box bg-white">
                      <label
                        className="my-auto form-check-label font-weight-bold"
                        htmlFor="webTiles"
                      >
                        Webtiles
                      </label>
                      <label className="container-cb mt-1 ml-1">
                        <input
                          type="checkbox"
                          className="mt-1"
                          checked={checkbox.webTiles}
                          onChange={handleOnCheckboxChange}
                          id="webTiles"
                        />
                        <span className="checkmark"></span>
                      </label>
                    </div>
                  )}

                  <div className="col-2"></div>
                </div>

                {input.version === "v2" && (
                  <div className="row mt-3">
                    <div className="col-2"></div>
                    <div className="col-4 d-flex justify-content-between p-2 px-2 mr-2 asset-box bg-white">
                      <label
                        className="my-auto form-check-label font-weight-bold"
                        htmlFor="virtualBackgrounds"
                      >
                        Virtual Backgrounds
                      </label>
                      <label className="container-cb mt-1 ml-1">
                        <input
                          type="checkbox"
                          className="mt-1"
                          checked={checkbox.virtualBackgrounds}
                          onChange={handleOnCheckboxChange}
                          id="virtualBackgrounds"
                        />
                        <span className="checkmark"></span>
                      </label>
                    </div>
                    {/* <div className="col-4 d-flex justify-content-between p-2 px-2 ml-2 asset-box bg-white">
                    <label
                      className="my-auto form-check-label font-weight-bold"
                      htmlFor="extendedTextboxPosters"
                    >
                      Extended Text Box Posters
                    </label>
                    <label className="container-cb mt-1 ml-1">
                      <input
                        type="checkbox"
                        className="mt-1"
                        checked={checkbox.extendedTextboxPosters}
                        onChange={handleOnCheckboxChange}
                        id="extendedTextboxPosters"
                      />
                      <span className="checkmark"></span>
                    </label>
                  </div> */}
                    <div className="col-2"></div>
                  </div>
                )}
                <div className="row">
                  <div className="col-2"></div>
                  <div className="col-4"></div>
                  <div className="col-5">
                    <div className="mb-3 mt-4">
                      <button
                        type="button"
                        className="btn primary-btn-prev"
                        style={{ width: "40%" }}
                        onClick={onBackHandler}
                      >
                        <i className="fas fa-long-arrow-alt-left"></i>{" "}
                        &nbsp;&nbsp; Previous
                      </button>
                      <button
                        type="button"
                        className="ml-4 btn primary-btn"
                        style={{ width: "40%" }}
                        disabled={checkboxNextDisable}
                        onClick={() => {
                          checkBoxCheck();
                        }}
                      >
                        Next &nbsp;&nbsp;{" "}
                        <i className="fas fa-long-arrow-alt-right"></i>
                      </button>
                    </div>
                  </div>
                  <div className="col-1"></div>
                </div>
              </div>
            </>
          ) : null}
          {count === 3 ? (
            <div className="container">
              <div className="row mt-2">
                <div className="col-2"></div>
                <div className="col-4">
                  <div className="p-auto px-1 text-center rounded d-flex justify-content-between">
                    <p className="m-0 text-dark font-weight-bold">
                      Registration Start Date
                    </p>
                    <i
                      className="fa fa-info-circle my-auto text-grey"
                      data-toggle="tooltip"
                      title="Enter the date when registration for program is starting. You can select your preferred date format."
                      aria-hidden="true"
                    ></i>
                  </div>
                  <input
                    type="text"
                    className="form-control form-control-personalise"
                    value={input.startDate}
                    onKeyPress={inputSpaceCheck}
                    name="startDate"
                    id="startDate"
                    onChange={handleOnInputChange}
                    placeholder="Input Date"
                  />
                </div>
                <div className="col-4">
                  <div className="p-auto px-1 text-center rounded d-flex justify-content-between">
                    <p className="m-0 text-dark font-weight-bold">
                      Call To Action (1)
                    </p>
                    <i
                      className="fa fa-info-circle my-auto text-grey"
                      data-toggle="tooltip"
                      title="Input words, numbers or special characters. Character limit is 15"
                      aria-hidden="true"
                    ></i>
                  </div>
                  <input
                    type="text"
                    className="form-control form-control-personalise"
                    maxLength="15"
                    value={input.cta1}
                    onKeyPress={inputSpaceCheck}
                    name="cta1"
                    id="cta1"
                    onChange={handleOnInputChange}
                    placeholder="Enter Your Text (max 15 Characters)"
                  />
                </div>
                <div className="col-2"></div>
              </div>

              <div className="row mt-2">
                <div className="col-2"></div>
                <div className="col-4">
                  <div className="p-auto px-1 text-center rounded d-flex justify-content-between">
                    <p className="m-0 text-dark font-weight-bold">
                      Call To Action (2)
                    </p>
                    <i
                      className="fa fa-info-circle my-auto text-grey"
                      data-toggle="tooltip"
                      title="Input words, numbers or special characters. Character limit is 15"
                      aria-hidden="true"
                    ></i>
                  </div>
                  <input
                    type="text"
                    className="form-control form-control-personalise"
                    maxLength="15"
                    value={input.cta2}
                    onKeyPress={inputSpaceCheck}
                    name="cta2"
                    id="cta2"
                    onChange={handleOnInputChange}
                    placeholder="Enter Your Text (max 15 Characters)"
                  />
                </div>
                <div className="col-4">
                  <div className="p-auto px-1 text-center rounded d-flex justify-content-between">
                    <p className="m-0 text-dark font-weight-bold">URL</p>
                    <i
                      className="fa fa-info-circle my-auto text-grey"
                      data-toggle="tooltip"
                      title="The URL will be assigned to each of the assets allowing user to activate the link by clicking anywhere on the asset. This will not be displayed as text on any asset. If the URL needs to be displayed, please enter it in Call to Action input."
                      aria-hidden="true"
                    ></i>
                  </div>
                  <input
                    type="text"
                    className="form-control form-control-personalise"
                    value={input.url}
                    onKeyPress={inputSpaceCheck}
                    name="url"
                    id="url"
                    onChange={handleOnInputChange}
                    placeholder="URL Link"
                  />
                </div>
                <div className="col-2"></div>
              </div>

              <div className="row mt-2">
                <div className="col-2"></div>
                <div className="col-4">
                  <div className="p-auto px-1 text-center d-flex justify-content-between">
                    <p className="m-0 text-dark font-weight-bold">Logo</p>
                    <i
                      className="fa fa-info-circle my-auto text-grey"
                      data-toggle="tooltip"
                      title="Only PNG, JPEG and JPG files with dimensions less than or equal to 500*500 are allowed"
                      aria-hidden="true"
                    ></i>
                  </div>
                  <div className="form-control form-control-personalise d-flex justify-content-between pr-4">
                    {fileNames.logo ? (
                      <p className="my-auto text-dark">{fileNames.logo}</p>
                    ) : (
                      <p className="my-auto text-secondary">No file Chosen</p>
                    )}
                    <div className="my-auto">
                      <label
                        className="btn primary-btn-prev file-btn my-auto"
                        htmlFor="logo"
                      >
                        <i className="fe fe-image"></i> Choose File
                      </label>
                      <input
                        type="file"
                        name="logo"
                        accept="image/jpeg,image/png,image/*"
                        id="logo"
                        className="d-none"
                        onChange={handleOnFileChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-4">
                  <div className="p-auto px-1 text-center d-flex justify-content-between">
                    <p className="m-0 text-dark font-weight-bold">QR Code</p>
                    <i
                      className="fa fa-info-circle my-auto text-grey"
                      data-toggle="tooltip"
                      title="Only PNG, JPEG and JPG files with dimensions less than or equal to 500*500 are allowed"
                      aria-hidden="true"
                    ></i>
                  </div>
                  <div className="form-control form-control-personalise d-flex justify-content-between pr-4">
                    {fileNames.qrCode ? (
                      <p className="my-auto text-dark">{fileNames.qrCode}</p>
                    ) : (
                      <p className="my-auto text-secondary">No file Chosen</p>
                    )}
                    <div className="my-auto">
                      <label
                        className="btn primary-btn-prev file-btn my-auto"
                        htmlFor="qrCode"
                      >
                        <i className="fe fe-image"></i> Choose File
                      </label>
                      <input
                        type="file"
                        name="qrCode"
                        accept="image/jpeg,image/png,image/*"
                        id="qrCode"
                        className="d-none"
                        onChange={handleOnFileChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-2"></div>
              </div>

              <div className="row mt-2">
                <div className="col-2"></div>
                <div className="col-4">
                  <div className="p-auto px-1 text-center d-flex justify-content-between">
                    <p className="m-0 text-dark font-weight-bold">
                      Registration Close Date
                    </p>
                    <i
                      className="fa fa-info-circle my-auto text-grey"
                      data-toggle="tooltip"
                      title="Enter the date when registration for program is closing. You can select your preferred date format."
                      aria-hidden="true"
                    ></i>
                  </div>
                  <input
                    type="text"
                    className="form-control form-control-personalise"
                    name="registrationCloseDate"
                    value={input.registrationCloseDate}
                    onKeyPress={inputSpaceCheck}
                    id="registrationCloseDate"
                    onChange={handleOnInputChange}
                    placeholder="Input Date"
                  />
                </div>
                <div className="col-4"></div>
                <div className="col-2"></div>
              </div>

              <div className="row">
                <div className="col-2"></div>
                <div className="col-4"></div>
                <div className="col-5">
                  <div className="mb-3">
                    <button
                      type="button"
                      className="ml-1 mt-3 btn primary-btn-prev"
                      style={{ width: "37%" }}
                      onClick={onBackHandler}
                    >
                      <i className="fas fa-long-arrow-alt-left"></i>{" "}
                      &nbsp;&nbsp; Previous
                    </button>
                    <button
                      type="button"
                      className="ml-3 mt-3 btn primary-btn"
                      style={{ width: "37%" }}
                      disabled={submitDisable}
                      onClick={() => {
                        onSubmit();
                      }}
                    >
                      Generate Assets
                    </button>
                  </div>
                </div>
                <div className="col-1"></div>
              </div>
            </div>
          ) : null}
          {count === 4 ? (
            <div className="container mt-5">
              <h1 className="text-center">
                Congratulations! Marketing Assets for {input.clientName} are
                ready for use!
              </h1>
              <div className="row mt-5">
                <div className="mx-auto">
                  <button
                    type="button"
                    className="btn primary-btn"
                    onClick={() => {
                      setInput(initialInputValues);
                      setCount(1);
                      setCheckbox(initialCheckboxValues);
                      setSelectAll(true);
                      setFileNames(initialFileNames);
                    }}
                  >
                    Start Again!
                  </button>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      )}
    </div>
  );
};

export default React.memo(Asset);
