import React from "react";
import * as Cookies from "js-cookie";
import { useMsal } from "@azure/msal-react";

const Navbar = (props) => {
  const { instance } = useMsal();

  const handleLogout = (logoutType) => {
    if (logoutType === "redirect") {
      instance.logoutRedirect({
        postLogoutRedirectUri: "/",
      });
    }
  };

  const handleOnLogout = () => {
    Cookies.remove("session");
    props.dispatch({ type: "USER", payload: {} });
  };

  return (
    <nav id="header" className="header-main bg-main">
      <div className="container-fluid py-3">
        <div className="row">
          <div className="col-10">
            <img
              src="images/VP_LOGO.png"
              className="ml-5 img-fluid nav-logo"
              alt="profile_image"
            />
          </div>
          <div className={`col-2 my-auto ${!props.isUser && "d-none"}`}>
            <button
              type="button"
              className="btn log-off-btn my-auto d-flex"
              onClick={() => handleLogout("redirect")}
            >
              <p className="my-auto p-0">Log Off</p>&nbsp; &nbsp;
              <i className="fas fa-sign-out-alt fa-2x"></i>
            </button>
          </div>
        </div>
      </div>
    </nav>
  );
};

// const mapStateToProps = (state) => {
//     return {
//         user: state.user
//     }
// }

// export default connect(mapStateToProps, undefined)(Navbar);
export default Navbar;
