import React, { useState } from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../authConfig";

const Login = (props) => {
  const [loading, setLoading] = useState(false);

  const { instance } = useMsal();

  const handleLogin = (loginType) => {
    setLoading(true);
    if (loginType === "redirect") {
      instance.loginRedirect(loginRequest).catch((e) => {
        setLoading(false);
        console.log(e);
      });
    }
  };

  return (
    <>
      {loading ? (
        <div className="container text-center mt-5">
          <div className="lds-ring">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      ) : (
        <div className="container p-5 text-center">
          <div className="text-center">
            <h2>DYNAMIC ASSETS</h2>
            <p style={{ color: "gray" }}>Please login to use this tool.</p>
          </div>
          <button
            className="btn microsoft-button d-flex offset-5"
            onClick={() => handleLogin("redirect")}
          >
            <img
              src="/images/microsoft.png"
              className="mr-2"
              height="15%"
              width="15%"
              alt="login_image"
            />
            <p className="my-auto">Microsoft Login</p>
          </button>
        </div>
      )}
    </>
  );
};

export default Login;
