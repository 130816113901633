import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { folderNames, Languages } from "../../constants";

axios.defaults.baseURL = process.env.REACT_APP_URL_BASE;

const FileDownload = (props) => {
  const [input, setInput] = useState({
    folderName: "",
    language: "",
    fileType: "",
  });
  const [loading, setLoading] = useState(false);
  const [accessToken, setAccessToken] = useState(null);

  const getToken = useCallback(() => {
    return async () => {
      var accessToken = await props.getAccessToken();
      setAccessToken(accessToken);
    };
  }, [props]);

  useEffect(() => {
    getToken();
  }, [getToken]);

  const handleOnChange = (event) => {
    const { name, value } = event.target;
    if (name === "fileType") {
      setInput((prevState) => ({
        ...prevState,
        [name]: value,
        folderName: "",
      }));
    } else {
      setInput((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };
  function download(strData, strFileName) {
    let D = document,
      A = arguments,
      a = D.createElement("a"),
      n = A[1];
    let newdata = `data:application/zip;base64,` + escape(strData);
    a.href = newdata;
    if ("download" in a) {
      a.setAttribute("download", n);
      D.body.appendChild(a);
      setTimeout(function () {
        var e = D.createEvent("MouseEvents");
        e.initMouseEvent(
          "click",
          true,
          false,
          window,
          0,
          0,
          0,
          0,
          0,
          false,
          false,
          false,
          false,
          0,
          null
        );
        a.dispatchEvent(e);
        D.body.removeChild(a);
      }, 66);
      return true;
    }
  }
  const onSubmit = async () => {
    try {
      if (input.folderName && input.language && input.fileType) {
        setLoading(true);
        const config = {
          headers: {
            "X-Requested-With": "XMLHttpRequest",
            "Access-Control-Allow-Origin": "*",
            Authorization: "Bearer " + accessToken,
            "Content-Type": "multipart/form-data",
          },
        };
        const formData = new FormData();
        formData.append("folderName", input.folderName);
        formData.append("language", input.language);
        formData.append("fileType", input.fileType);
        const response = await axios.post("/assets/download", formData, config);
        switch (response.data.response.responseCode) {
          case 10000:
            const strData = response.data.response.zip;
            download(strData, input.folderName);
            setLoading(false);
            toast.success("Success");
            break;
          case 10001:
            setLoading(false);
            toast.error("Asset files empty");
            break;
          default:
            toast.error("There was an error");
            setLoading(false);
            break;
        }
      } else {
        toast.error("Please fill all fields!");
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong. Please try again after sometime");
    }
  };

  return (
    <div className="container p-5">
      {loading ? (
        <div className="container text-center mt-5">
          <div className="lds-ring">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      ) : (
        <form>
          <div className="text-center">
            <h2>ASSET DOWNLOAD</h2>
          </div>
          <div className="row">
            <div className="col-6 dropdown mt-3">
              <label htmlFor="fileType">File Type</label>
              <select
                className="form-control form-control-lp"
                id="fileType"
                name="fileType"
                onChange={handleOnChange}
                value={input.fileType}
                required
              >
                <option value="">Select File Type</option>
                <option value="pdf">PDF</option>
                <option value="ppt">PPT</option>
              </select>
            </div>
            <div className="col-6 dropdown mt-3">
              <label htmlFor="folderName">Folder Name</label>
              <select
                className="form-control form-control-lp"
                name="folderName"
                id="folderName"
                onChange={handleOnChange}
                value={input.folderName}
                required
              >
                <option value="">Select Folder Name</option>
                {folderNames?.map((folderName) => (
                  <option value={folderName} key={folderName}>
                    {folderName}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="row">
            <div className="col-6 pr-5 mt-3">
              <label htmlFor="language">Language</label>
              <select
                className="form-control form-control-lp"
                name="language"
                id="language"
                onChange={handleOnChange}
                value={input.language}
                required
              >
                <option value="">Select Language</option>
                {Languages?.map((item) => (
                  <option value={item?.code} key={item.code}>
                    {item.language}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <button
            type="submit"
            className="btn primary-btn mt-3 px-5 text-center"
            onClick={onSubmit}
          >
            Download
          </button>
        </form>
      )}
    </div>
  );
};

export default FileDownload;
