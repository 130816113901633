import "./App.css";
import { useState } from "react";
import { Router, Switch, Route } from "react-router-dom";
import { createBrowserHistory } from "history";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { callMsGraph } from "./graph";
import { loginRequest } from "./authConfig";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from "@azure/msal-react";

import Login from "./Pages/Login";
import Asset from "./Pages/Asset";
import Navbar from "./Components/navbar";
import AssetUpload from "./Pages/AssetUpload";
import FileDownload from "./Pages/FileDownload";

const history = createBrowserHistory();
toast.configure();

function App(props) {
  const { instance, accounts } = useMsal();
  const [graphData, setGraphData] = useState(null);
  const [isUser, setIsUser] = useState(false);

  function RequestProfileData() {
    return instance
      .acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
      })
      .then((response) => {
        callMsGraph(response.accessToken).then((response) => {
          setIsUser(true);
        });
        return response.accessToken;
      });
  }

  return (
    <>
      <Navbar isUser={isUser} />
      {/* <Router history={history}>
          <Switch>
            <Route exact path='/upload' component={AssetUpload} />
          </Switch>
        </Router> */}
      <AuthenticatedTemplate>
        <Router history={history}>
          <Switch>
            <Route
              exact
              path="/"
              component={() => (
                <Asset user={isUser} getAccessToken={RequestProfileData} />
              )}
            />
            <Route
              exact
              path="/upload"
              component={AssetUpload}
              getAccessToken={RequestProfileData}
            />
            <Route
              exact
              path="/file-download"
              component={FileDownload}
              getAccessToken={RequestProfileData}
            />
          </Switch>
        </Router>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <Login />
      </UnauthenticatedTemplate>
    </>
  );
}

export default App;
