export const Languages = [
  {
    language: "English (US & UK)",
    code: "EN-US",
  },
  {
    language: "German",
    code: "DE-DE",
  },

  {
    language: "Spanish (Spain & Latin America)",
    code: "ES-ES",
  },

  {
    language: "French (France & Canada)",
    code: "FR-FR",
  },
  {
    language: "Japanese",
    code: "JA-JP",
  },

  {
    language: "Dutch",
    code: "NL-NL",
  },
];

export const oldLanguageList = [
  {
    language: "English (US)",
    code: "EN-US",
  },
  {
    language: "German",
    code: "DE-DE",
  },
  {
    language: "Portuguese (Brazil)",
    code: "PT-BR",
  },
  {
    language: "Spanish (Spain)",
    code: "ES-ES",
  },
  {
    language: "Spanish (Latin America)",
    code: "ES-LA",
  },
  {
    language: "French (France)",
    code: "FR-FR",
  },
  {
    language: "Japanese",
    code: "JA-JP",
  },
  {
    language: "Italian",
    code: "IT-IT",
  },
  {
    language: "Dutch",
    code: "NL-NL",
  },
  {
    language: "Polish",
    code: "PL-PL",
  },
  {
    language: "Swedish",
    code: "SV-SE",
  },
  {
    language: "Korean",
    code: "KO-KR",
  },
  {
    language: "English (UK)",
    code: "EN-GB",
  },
  {
    language: "French (Canada)",
    code: "FR-CA",
  },
  {
    language: "Malay",
    code: "MS-MY",
  },
  {
    language: "Russian",
    code: "RU-RU",
  },
  {
    language: "Vietnamese",
    code: "VI-VN",
  },
  {
    language: "Thai",
    code: "TH-TH",
  },
  {
    language: "Chinese (Simplified)",
    code: "ZH-CN",
  },
  {
    language: "Chinese (Hong Kong)",
    code: "ZH-HK",
  },
];

export const folderNames = [
  "Email Banner",
  "Flyers",
  "Digital Display",
  "Payslip",
  "Posters",

  "Virtual Backgrounds",
  "Webtiles",
];
