import React, { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import axios from "axios";
import { toast } from "react-toastify";
import { folderNames, Languages } from "../../constants";

// axios.defaults.baseURL = process.env.REACT_APP_URL_CLIENT;

const AssetUpload = (props) => {
  const [input, setInput] = useState({
    folderName: "",
    language: "",
    type: "pdf",
  });
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [accessToken, setAccessToken] = useState(null);

  const getToken = useCallback(() => {
    return async () => {
      var accessToken = await props.getAccessToken();
      setAccessToken(accessToken);
    };
  }, [props]);

  useEffect(() => {
    getToken();
  }, [getToken]);

  const onDrop = useCallback((acceptedFiles) => {
    setFiles([...acceptedFiles]);
  }, []);
  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const handleOnInputChange = (e) => {
    const { name, value } = e.target || "";
    if (name === "type") {
      setInput({
        folderName: "",
        language: "",
        type: value,
      });
    } else {
      setInput((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const onSubmit = async () => {
    var assetFields =
      input.type === "pdf"
        ? JSON.stringify({
            language: input.language,
            assetFields: [
              {
                fieldName: "cta1",
                fieldType: "string",
                fieldValue: "Insert Start date ",
              },
              {
                fieldName: "cta2",
                fieldType: "string",
                fieldValue: "and URL",
              },
              {
                fieldName: "logo",
                fieldType: "image",
                fieldValue: "CLIENT LOGO",
              },
              {
                fieldName: "qrcode",
                fieldType: "image",
                fieldValue: "QR CODE",
              },
            ],
          })
        : JSON.stringify({
            language: input.language,
            assetFields: [
              {
                fieldName: "cta1",
                fieldType: "string",
                fieldValue: "<< date>> ",
              },
              {
                fieldName: "cta2",
                fieldType: "string",
                fieldValue: "URL link",
              },
              {
                fieldName: "clientName",
                fieldType: "string",
                fieldValue: "<<CLIENT>>",
              },
              {
                fieldName: "logo",
                fieldType: "image",
                fieldValue: "Client Logo",
              },
              {
                fieldName: "qrcode",
                fieldType: "image",
                fieldValue: "QR Code",
              },
            ],
          });

    if (input.folderName && files.length > 0 && input.language) {
      setLoading(true);
      for (var i = 0; i < files.length; i++) {
        const formData = new FormData();
        formData.append("assetFields", assetFields);
        formData.append("folderName", input.folderName);
        formData.append("file", files[i]);
        const config = {
          headers: {
            "X-Requested-With": "XMLHttpRequest",
            "Access-Control-Allow-Origin": "*",
            Authorization: "Bearer " + accessToken,
          },
        };
        await axios
          .post(
            `${process.env.REACT_APP_URL_BASE}/file/upload`,
            formData,
            config
          )
          // eslint-disable-next-line no-loop-func
          .then((response) => {
            switch (response.data.status.code) {
              case 10000:
                toast.success(i + 1, "File Uploaded");
                break;
              default:
                toast.error("error!");
                break;
            }
          })
          .catch((error) => {
            toast.error("Something went wrong. please try again!");
          });
      }
      setLoading(false);
      setInput({
        folderName: "",
        language: "",
        type: "",
      });
    } else {
      if (input.folderName && input.language && !files.length)
        toast.error("Please select files!");
      else toast.error("Please fill all fields!");
    }
  };

  return (
    <div className="container p-5">
      {loading ? (
        <div className="container text-center mt-5">
          <div className="lds-ring">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      ) : (
        <form>
          <div className="row">
            <div className="col-6 dropdown mt-3">
              <label htmlFor="name">File Type</label>
              <select
                className="form-control form-control-lp"
                id="name"
                name="type"
                onChange={handleOnInputChange}
                value={input.type}
                required
              >
                <option value="pdf">PDF</option>
                <option value="ppt">PPT</option>
              </select>
            </div>
            <div className="col-6 dropdown mt-3">
              <label htmlFor="folderName">Folder Name</label>
              <select
                className="form-control form-control-lp"
                name="folderName"
                id="folderName"
                onChange={handleOnInputChange}
                value={input.folderName}
                required
              >
                <option hidden value="">
                  Select one...
                </option>
                {folderNames?.map((folderName) => (
                  <option value={folderName} key={folderName}>
                    {folderName}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="row">
            <div className="col-6 pr-5 mt-3">
              <label htmlFor="language">Language</label>
              <select
                className="form-control form-control-lp"
                name="language"
                onChange={handleOnInputChange}
                value={input.language}
              >
                <option hidden value="">
                  Select Language
                </option>
                {Languages?.map((item) => (
                  <option value={item?.code} key={item.code}>
                    {item.language}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-6" {...getRootProps()}>
              <input {...getInputProps()} />
              <button type="button" className="btn primary-btn mt-3 px-3">
                Select Files
              </button>
            </div>
          </div>
          <button
            type="button"
            className="btn primary-btn mt-3 px-5 text-center"
            onClick={onSubmit}
          >
            Submit
          </button>
        </form>
      )}
    </div>
  );
};

export default AssetUpload;
